import { useState, useEffect } from "react";
import { db } from "../../firebaseConfig"; // Adjust the import path as necessary
import {
  Product,
  ProductType,
  AuctionProduct,
  SaleProduct,
} from "../../interface/data"; // Adjust the import path
import firebase from "firebase";

const useUnpaidProducts = () => {
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    const now = new Date();

    const unsubscribe = db
      .collection("products")
      .where("data.seller", "!=", null)
      .onSnapshot(
        (snapshot) => {
          const unpaidProducts = snapshot.docs

            .map((doc) => {
              const data = doc.data();
              const product = { ...data, productId: doc.id } as Product;
              if (product.buyer == "") {
                if (product.type === ProductType.Auction) {
                  let deadlineDate;

                  // Convert the deadline to a Date object if it's a Firestore Timestamp
                  if (product.data.deadline?.toDate) {
                    deadlineDate = product.data.deadline.toDate();
                  } else if (product.data.deadline instanceof Date) {
                    deadlineDate = product.data.deadline;
                  } else {
                    return null;
                  }

                  const deadlineNotPassed = deadlineDate > now;
                  console.log(" deadline");

                  console.log(deadlineDate);
                  console.log(now);

                  console.log("unpaidProducts deadline");
                  console.log(product);

                  return deadlineNotPassed ? product : null;
                } else {
                  return product;
                }
              }
            })
            .filter(Boolean);

          setProducts(unpaidProducts as Product[]);
        },

        (err) => {
          console.error(`Encountered error: ${err}`);
        }
      );

    return () => {
      unsubscribe();
    };
  }, []);

  return products;
};

export default useUnpaidProducts;
