import React, { useEffect, useState } from "react";
import { Account, Product, ProductType } from "../../interface/data"; // Adjust the path accordingly
import useProfil from "../../hook/web2/useProfil";
import { db } from "../../firebaseConfig";
import useSale from "../../hook/web3/useSale";
import useFetchAuctionData from "../../hook/web3/AuctionData/useFetchAuctionData";
import useAuction from "../../hook/web3/useAuction";
import useFetchSaleData from "../../hook/web3/SaleData/useFetchSaleData";
import { useAccount } from "wagmi";

interface ProductItemProps {
  product: Product;
}

const ProductItemAuctionBuy: React.FC<ProductItemProps> = ({ product }) => {
  const { auctionData, loadingAuction, errorAuction } = useFetchAuctionData(
    product.contract
  );
  const { productsBuy } = useProfil();
  const { address } = useAccount();

  const { BidderConfirm, auctionEnd } = useAuction();

  const confirmBid = async (productId: string | undefined) => {
    const product = productsBuy.find((p) => p.productId === productId);

    if (product && product.type === ProductType.Auction && product.contract) {
      try {
        await BidderConfirm(product.contract);
        await db.runTransaction(async (transaction) => {
          const productRef = db.collection("products").doc(product.productId);
          const accountRef = db.collection("accounts").doc(address);

          const productDoc = await transaction.get(productRef);
          const accountDoc = await transaction.get(accountRef);

          if (!productDoc.exists) {
            throw new Error("Product document does not exist!");
          }

          const accountData = accountDoc.data() as Account;

          transaction.update(accountRef, { buyer: "" });

          const updatedBuyList = accountData.buy
            ? accountData.buy.filter((id) => id !== product.productId)
            : [];
          transaction.update(accountRef, { buy: updatedBuyList });

          transaction.delete(productRef);
        });
        console.log(
          "Order accepted and product removed for product ID:",
          productId
        );
        alert("Payment confirmed");
      } catch (error) {
        console.error(
          "Error confirming order and removing product for product ID:",
          productId,
          error
        );
        alert(
          "An error occurred during the transaction. Please try again later."
        );
      }
    } else {
      console.error(
        "No product found with ID or product is not a Sale:",
        productId
      );
    }
  };

  const cancelAuction = async (productId: string | undefined) => {
    const product = productsBuy.find((p) => p.productId === productId);

    if (product && product.type === ProductType.Auction && product.contract) {
      try {
        const saleEnded = await auctionEnd(product.contract);
        await db.runTransaction(async (transaction) => {
          const productRef = db.collection("products").doc(product.productId);
          const accountRef = db.collection("accounts").doc(address);

          const productDoc = await transaction.get(productRef);
          const accountDoc = await transaction.get(accountRef);

          if (!productDoc.exists) {
            throw new Error("Product document does not exist!");
          }

          const accountData = accountDoc.data() as Account;

          transaction.update(accountRef, { buyer: "" });

          const updatedBuyList = accountData.buy
            ? accountData.buy.filter((id) => id !== product.productId)
            : [];
          transaction.update(accountRef, { buy: updatedBuyList });

          transaction.delete(productRef);
        });
        if (saleEnded) {
          console.log("Sale ended for product ID:", productId);
          alert("Auction ended successfully.");
        } else {
          console.log("Auction could not be ended for product ID:", productId);
        }
      } catch (error) {
        console.error("Error ending sale for product ID:", productId, error);
        alert(
          "An error occurred while attempting to end the Auction. Please try again later."
        );
      }
    } else {
      console.error(
        "No product found with ID, or product is not for Auction:",
        productId
      );
      alert("No Auction product found with the provided ID.");
    }
  };

  let content;
  if (product.type === ProductType.Auction) {
    if (loadingAuction) content = <p>Loading auction data...</p>;
    else if (errorAuction) content = <p>Error: {errorAuction.message}</p>;
    else if (
      auctionData &&
      auctionData.highestBidder == address &&
      product.data.deadline &&
      product.data.deadline.toDate() < new Date()
    ) {
      content = (
        <div>
          <h4 className="font-bold">{product.title}</h4>

          <p>Price: {auctionData.highestBid} ETH</p>

          <>
            <button
              onClick={() => confirmBid(product.productId as string)}
              className="border bottom-2 bg-blue-200 p-2"
            >
              Confirm Bid
            </button>
            <button
              onClick={() => cancelAuction(product.productId as string)}
              className="border bottom-2 bg-red-200 p-2"
            >
              Cancel payment
            </button>
          </>
        </div>
      );
    }
  } else {
    content = (
      <div>
        {product.title}
        <p>Price: {auctionData && auctionData.highestBid} ETH</p>
      </div>
    );
  }

  return (
    <li className="mb-2 p-3 border-2 border-gray-800 rounded">{content}</li>
  );
};

const ProductItemSaleBuy: React.FC<ProductItemProps> = ({ product }) => {
  const { saleData, loadingSale, errorSale } = useFetchSaleData(
    product.contract
  );
  const { productsBuy } = useProfil();
  const { address } = useAccount();

  const { BuyerConfirm, endSale } = useSale();

  const confirmPayment = async (productId: string | undefined) => {
    const product = productsBuy.find((p) => p.productId === productId);

    if (product && product.type === ProductType.Sale && product.contract) {
      try {
        await BuyerConfirm(product.contract);
        await db.runTransaction(async (transaction) => {
          const productRef = db.collection("products").doc(product.productId);
          const accountRef = db.collection("accounts").doc(address);

          const productDoc = await transaction.get(productRef);
          const accountDoc = await transaction.get(accountRef);

          if (!productDoc.exists) {
            throw new Error("Product document does not exist!");
          }

          const accountData = accountDoc.data() as Account;

          transaction.update(productRef, { hasPaid: true });
          transaction.update(accountRef, { buyer: "" });

          const updatedBuyList = accountData.buy
            ? accountData.buy.filter((id) => id !== product.productId)
            : [];
          transaction.update(accountRef, { buy: updatedBuyList });

          transaction.delete(productRef);
        });
        console.log(
          "Order accepted and product removed for product ID:",
          productId
        );
        alert("Payment confirmed");
      } catch (error) {
        console.error(
          "Error confirming order and removing product for product ID:",
          productId,
          error
        );
        alert(
          "An error occurred during the transaction. Please try again later."
        );
      }
    } else {
      console.error(
        "No product found with ID or product is not a Sale:",
        productId
      );
    }
  };

  const cancelPayment = async (productId: string | undefined) => {
    const product = productsBuy.find((p) => p.productId === productId);

    if (product && product.type === ProductType.Sale && product.contract) {
      try {
        const saleEnded = await endSale(product.contract);
        if (saleEnded) {
          console.log("Sale ended for product ID:", productId);
          alert("Sale ended successfully.");
        } else {
          console.log("Sale could not be ended for product ID:", productId);
        }
      } catch (error) {
        console.error("Error ending sale for product ID:", productId, error);
        alert(
          "An error occurred while attempting to end the sale. Please try again later."
        );
      }
    } else {
      console.error(
        "No product found with ID, or product is not for sale:",
        productId
      );
      alert("No saleable product found with the provided ID.");
    }
  };

  let content;
  if (product.type === ProductType.Sale) {
    if (loadingSale) content = <p>Loading auction data...</p>;
    else if (errorSale) content = <p>Error: {errorSale.message}</p>;
    else if (saleData && product.data.seller != "") {
      content = (
        <div>
          <h4 className="font-bold">{product.title}</h4>

          <p>Price: {saleData.price} ETH</p>

          <>
            <button
              onClick={() => confirmPayment(product.productId as string)}
              className="border bottom-2 bg-blue-200 p-2"
            >
              Confirm payment
            </button>
            <button
              onClick={() => cancelPayment(product.productId as string)}
              className="border bottom-2 bg-red-200 p-2"
            >
              Cancel payment
            </button>
          </>
        </div>
      );
    }
  } else {
    content = <div>{product.title}</div>;
  }

  return (
    <li className="mb-2 p-3 border-2 border-gray-800 rounded">{content}</li>
  );
};

const ProductItemAuction: React.FC<ProductItemProps> = ({ product }) => {
  const { auctionData, loadingAuction, errorAuction } = useFetchAuctionData(
    product.contract
  );

  const confirmBid = async (productId: string): Promise<void> => {
    console.log("accept Bid");
    try {
      const productRef = db.collection("products").doc(productId);
      await productRef.update({ "data.confirmed": true });
      console.log(`Product with ID ${productId} has been confirmed.`);
    } catch (error) {
      console.error("Error updating product confirmation:", error);
    }
  };

  const cancelOrder = async () => {
    console.log("cancel order");
  };

  let content;
  if (product.type === ProductType.Auction) {
    if (loadingAuction) content = <p>Loading auction data...</p>;
    else if (errorAuction) content = <p>Error: {errorAuction.message}</p>;
    else if (auctionData && product.data.seller != "") {
      content = (
        <div>
          <div className="p-3 border-2 border-gray-800 rounded">
            <h4 className="font-bold">{product.title}</h4>
          </div>
          <p>Highest Bid: {auctionData.highestBid}</p>
          {product.type == ProductType.Auction &&
            product.data.deadline &&
            product.data.deadline.toDate() < new Date() &&
            !product.data.confirmed && (
              <>
                <button
                  onClick={() => confirmBid(product.productId as string)}
                  className="border bottom-2 bg-blue-200 p-2"
                >
                  Confirm bid
                </button>
                <button
                  onClick={() => cancelOrder()}
                  className="border bottom-2 bg-red-200 p-2"
                >
                  Cancel order
                </button>
              </>
            )}
        </div>
      );
    }
  } else {
    content = (
      <div>
        <p>{product.title}</p>
      </div>
    );
  }

  return (
    <li className="mb-2 p-3 border-2 border-gray-800 rounded">{content}</li>
  );
};

const AccountPage: React.FC = () => {
  const { account, productsBuy, productsSell } = useProfil();
  const { HighestBidder, HighestBid, StartingPrice } = useAuction();
  const [hb, setHb] = useState<string>();

  const [name, setName] = useState(account?.name || "");

  const buyLength = productsBuy?.length ?? 0;
  const sellLength = productsSell?.length ?? 0;
  const { BuyerConfirm } = useSale();

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleSetName = async () => {
    if (account?.address) {
      try {
        const accountRef = db.collection("accounts").doc(account.address);
        await accountRef.update({ name });
        console.log("Name updated successfully");
      } catch (error) {
        console.error("Error updating name:", error);
      }
    }
  };

  return (
    <div className="container mx-auto p-4 bg-gradient-to-br from-gray-200 to-gray-300 border border-gray-400 shadow-xl mt-8">
      <h1 className="text-3xl font-bold mb-4 text-gray-800">
        Account Information
      </h1>
      <p className="text-lg">
        <strong className="font-bold">Address:</strong> {account?.address}
      </p>
      {account?.name && (
        <p className="text-lg">
          <strong className="font-bold">Username:</strong> {account.name}
        </p>
      )}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Enter your Username"
          value={name}
          onChange={handleNameChange}
          className="text-lg p-2 border-2 border-gray-400 rounded-md shadow-inner bg-white"
        />
        <button
          onClick={handleSetName}
          className="ml-2 bg-gray-400 hover:bg-gray-500 text-black font-bold py-2 px-4 rounded shadow"
        >
          {account?.name ? "Update Username" : "Add Username"}
        </button>
      </div>

      <h2 className="text-xl font-semibold mt-6 mb-4 text-gray-800">
        Orders ({buyLength + sellLength})
      </h2>

      <div className="grid grid-cols-2 gap-4">
        <div className="orders-buy">
          <h3 className="text-lg font-medium mb-2 text-gray-800">
            Buy ({buyLength})
          </h3>
          <ul>
            {productsBuy.map((product, index) => (
              <li key={index} className="mb-2">
                <p>Product Auction </p>
                <ProductItemAuctionBuy key={index} product={product} />
                <div>
                  <p>Product sale </p>
                  <ProductItemSaleBuy key={index} product={product} />
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="orders-sell">
          <h3 className="text-lg font-medium mb-2 text-gray-800">
            Sell ({sellLength})
          </h3>
          <ul>
            {productsSell.map((product, index) => (
              <li key={index} className="mb-2">
                {product.type === ProductType.Auction && (
                  <>
                    <p>Product Auction</p>
                    <ProductItemAuction key={index} product={product} />
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
