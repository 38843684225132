import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebaseConfig";
import { Account, Review } from "../../interface/data";
import useProfil from "../../hook/web2/useProfil";
import firebase from "firebase/app";
import "firebase/firestore";
import { useAccount } from "wagmi";

const PublicProfile: React.FC = () => {
  const { address_ } = useParams<{ address_: string }>();
  const { address } = useAccount();
  const [account_, setAccount] = useState<Account | null>(null);
  const [newReviewMessage, setNewReviewMessage] = useState("");
  const [newReviewStars, setNewReviewStars] = useState(0);

  useEffect(() => {
    const fetchAccount = async () => {
      const docRef = db.collection("accounts").doc(address_);
      const doc = await docRef.get();
      if (doc.exists) {
        setAccount(doc.data() as Account);
      }
    };

    fetchAccount();
  }, [address_]);

  const handleAddReview = async () => {
    const depositor: Account = {
      address: address,
    };

    const newReview: Review = {
      depositor: depositor,
      message: newReviewMessage,
      stars: newReviewStars,
    };

    await db
      .collection("accounts")
      .doc(address_)
      .update({
        reviews: firebase.firestore.FieldValue.arrayUnion(newReview),
      });

    const updatedDoc = await db.collection("accounts").doc(address_).get();
    setAccount(updatedDoc.data() as Account);
  };

  return (
    <div className="bg-gray-200 p-4 rounded-md shadow-md">
      <h1 className="text-2xl font-bold text-gray-800 mb-4">Public Profile</h1>
      {account_?.address && <p className="text-gray-700">Address: {account_.address}</p>}
      {account_?.name && <p className="text-gray-700">Name: {account_.name}</p>}

      <h2 className="text-xl font-semibold text-gray-800 mt-6">Reviews:</h2>
      <div className="space-y-4">
        {account_?.reviews && account_.reviews.length > 0 ? (
          account_.reviews.map((review, index) => (
            <div key={index} className="bg-gray-100 p-3 rounded shadow">
              <p className="text-gray-700">Depositor Address: {review.depositor.address}</p>
              {review.depositor.name && (
                <p className="text-gray-700">Depositor Name: {review.depositor.name}</p>
              )}
              <p className="text-gray-700">Message: {review.message}</p>
              <p className="text-gray-700">Stars: {"★".repeat(review.stars)}</p>
            </div>
          ))
        ) : (
          <p className="text-gray-500">No reviews yet.</p>
        )}
      </div>

      <div className="mt-6">
        <h2 className="text-xl font-semibold text-gray-800 mb-2">Add a Review</h2>
        <textarea
          className="w-full p-2 border-2 border-gray-300 rounded-md focus:outline-none focus:border-gray-500"
          value={newReviewMessage}
          onChange={(e) => setNewReviewMessage(e.target.value)}
          placeholder="Leave a review"
          rows={3}
        ></textarea>
        <input
          className="w-1/3 p-2 mt-2 border-2 border-gray-300 rounded-md focus:outline-none focus:border-gray-500"
          type="number"
          value={newReviewStars}
          onChange={(e) => setNewReviewStars(parseInt(e.target.value))}
          placeholder="Stars (0-5)"
          min="0"
          max="5"
        />
        <button 
          className="mt-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
          onClick={handleAddReview}
        >
          Comment
        </button>
      </div>
    </div>
  );
};

export default PublicProfile;
