import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAccount } from "wagmi";
import { db } from "../../firebaseConfig";
import {
  ChatSession,
  Message,
  Product,
  ProductType,
} from "../../interface/data";
import { useCart } from "../../hook/CartContext";
import firebase from "firebase/app";
import "firebase/firestore";

const Chat: React.FC = () => {
  const { address } = useAccount();
  const [products, setProducts] = useState<Product[]>([]);
  const [chatSessions, setChatSessions] = useState<any[]>([]); // Update the type to match your ChatSession interface
  const { cart } = useCart();

  useEffect(() => {
    if (!address) return;

    // Fetch products where transactionFinished is true
    const fetchProducts = async () => {
      const querySnapshot = await db
        .collection("products")
        .where("buyer", "!=", undefined || "")
        .get();

      const fetchedProducts: Product[] = [];
      querySnapshot.forEach((doc) => {
        const product = doc.data() as Product;
        if (product.data?.seller === address || product.buyer === address) {
          fetchedProducts.push(product);
        }
      });

      console.log(fetchedProducts);

      setProducts(fetchedProducts);
    };

    fetchProducts();

    const unsubscribe = db.collection("chats").onSnapshot((snapshot) => {
      const chats: ChatSession[] = [];
      snapshot.forEach((doc) => {
        const session = doc.data() as ChatSession;
        const isUserInvolved = session.messages.some(
          (msg) => msg.sender === address || msg.recipient === address
        );
        if (isUserInvolved) {
          chats.push(session);
        }
      });
      console.log(chats);
      setChatSessions(chats);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [address]);

  const formatTimestamp = (timestamp: firebase.firestore.Timestamp): string => {
    return timestamp.toDate().toLocaleString();
  };

  return (
    <div className="flex justify-center bg-white-100 p-6">
      {chatSessions.map((session, index) => (
        <li key={index} className="mb-4">
          <div className="bg-gray-300 border border-gray-400 rounded-sm p-4">
            <h3 className="font-bold text-lg">
              Chat session started on:
              {formatTimestamp(session.messages[0].timestamp)}
            </h3>
            {session.messages.map((message: Message, msgIndex: number) => (
              <div
                key={msgIndex}
                className={`message ${
                  message.sender === address ? "sent" : "received"
                }`}
              >
                {message.sender === address ? (
                  <p>sent to {message.recipient} </p>
                ) : (
                  <p>Receive from {message.sender} </p>
                )}

                <p className="message-content">{message.content}</p>
                <p className="message-timestamp">
                  {formatTimestamp(message.timestamp)}
                </p>
                <Link to={`/chat/${message.recipient}`}>Go to chat</Link>
              </div>
            ))}
          </div>
        </li>
      ))}
      <ul className="w-full max-w-2xl">
        {cart.map((e) => (
          <ul>
            <li>
              chat with {e.data.seller} for {e.title} in Cart
            </li>
            <Link
              to={`/chat/${e.data.seller}`}
              className="text-gray-600 hover:text-gray-700"
            >
              Contact seller
            </Link>
          </ul>
        ))}
        {products.map((product, index) => (
          <>
            <li
              key={index}
              className="bg-gray-300 border border-gray-400 rounded-sm p-4 mb-4"
            >
              <div>
                <p className="text-gray-600">Your valid payment</p>
                <p className="font-bold text-gray-800">{product.title}</p>
                <p className="text-gray-700">
                  Price:{" "}
                  {product.type === ProductType.Auction
                    ? product.data.highestBid
                    : product.data.price}
                </p>
                <div className="mt-2 p-2 bg-gray-200 rounded-sm hover:bg-gray-300 transition-colors cursor-pointer">
                  {address === product.buyer && (
                    <Link
                      to={`/chat/${product.data.seller}`}
                      className="text-gray-600 hover:text-gray-700"
                    >
                      Contact seller
                    </Link>
                  )}
                  {address === product.data?.seller && (
                    <Link
                      to={`/chat/${product.buyer}`}
                      className="text-gray-600 hover:text-gray-700"
                    >
                      Contact buyer
                    </Link>
                  )}
                </div>
              </div>
            </li>
          </>
        ))}
      </ul>
    </div>
  );
};

export default Chat;
