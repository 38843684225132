import React, { useState, useEffect } from 'react';
import useStaking from '../hook/web3/useStaking';
import useErc20Token from '../hook/web3/useErc20';
import { useAccount } from "wagmi";




const Staking = () => {
  const { Deposit, Withdraw, StakingBalance, TVL} = useStaking()
 
  const [balanceStake, setBalanceStake] = useState('0');
  const [tokenBalance, setTokenBalance] = useState('0');
  const [tvl, setTvl] = useState('0'); 
  const [amount, setAmount] = useState(''); 
  const { address } = useAccount();
  const { BalanceOf, Approve, CheckApproval } = useErc20Token();
  const [hasApproved, setHasApproved] = useState(false);
  

  useEffect(() => {
   
    if (address) {
      CheckApproval(address).then(setHasApproved);
    }
  }, [address, CheckApproval]);

   useEffect(() => {
    TVL().then(setTvl); 
    
  }, [TVL]);

 
  useEffect(() => {
    if (address) {
      StakingBalance(address).then(setBalanceStake); 
      BalanceOf(address).then(setTokenBalance); 
      
    }
    
  }, [address, StakingBalance, BalanceOf]);

  

  const handleAmountChange = (e: { target: { value: React.SetStateAction<string>; }; }) => { 
    setAmount(e.target.value);
  }

  const handleDeposit = () => { 
    Deposit(amount);
  }

  const handleWithdraw = () => { 
    Withdraw(amount);
  }

  const handleApprove = async () => {
    try {
      await Approve(); 
      setHasApproved(true);
    } catch (error) {
      console.error("Approval failed", error);
    }
  };


  

  if (!address) {
    return (
      <div className="bg-gray-200 p-4 min-h-screen">
        <div className="container mx-auto">
          <div className="bg-gray-300 p-6 rounded-lg shadow-md">
            <p className="text-gray-700 text-xl">Please connect your wallet.</p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="bg-gray-200 p-4 min-h-screen">
      <div className="container mx-auto">
        <div className="bg-gray-300 p-6 rounded-lg shadow-md">
          <h2 className="text-gray-700 text-xl font-bold mb-4">Stake Your $TNR</h2>
          <div className="mb-4">
            <p className="text-gray-700 text-sm">Your Token Balance: {tokenBalance} TNR</p>
          </div>

       
          <div className="mb-4">
            <p className="text-gray-700 text-sm">Your Staked Balance: {balanceStake} TNR</p>
          </div>
        
          <div className="mb-4">
      <p className="text-gray-700 text-sm">Total Value Staked: {tvl}$</p> 
          </div>
          <div className="mb-6">
            <label htmlFor="amount" className="block text-gray-700 text-sm font-bold mb-2">
              Enter amount:
            </label>
            <input
              id="amount"
              type="text"
              placeholder="0.0"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={amount}
              onChange={handleAmountChange}
            />
            {!hasApproved ? (
              <button
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                onClick={handleApprove}
              >
                Approve
              </button>
            ) : (
              <div className="flex justify-between gap-4 mb-4">
                <button
                  className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded w-full"
                  onClick={handleDeposit}
                >
                  Deposit
                </button>
                <button
                  className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded w-full"
                  onClick={handleWithdraw}
                >
                  Withdrawal
                </button>
              </div>
            )}
          </div>
          <p className="text-gray-600 text-xs italic">
            A penalty of 10% is charged if you withdraw before the claiming day.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Staking;
