// CartContext.tsx
import React, { createContext, useContext, useEffect, useState } from "react";
import { Product } from "../interface/data";

type CartContextType = {
  cart: Product[];
  addToCart: (product: Product) => void;
  deleteCart: (product: Product) => void;
};

const CartContext = createContext<CartContextType | undefined>(undefined);

type CartProviderProps = {
  children: React.ReactNode;
};

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const [cart, setCart] = useState<Product[]>([]);

  const addToCart = (product: Product) => {
    setCart((prevCart) => [...prevCart, product]);
  };

  const deleteCart = (product: Product) => {
    setCart((prevCart) =>
      prevCart.filter((prod) => prod.productId !== product.productId)
    );
  };
  useEffect(() => {
    console.log(cart);
  }, [cart]);

  return (
    <CartContext.Provider value={{ cart, addToCart, deleteCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
