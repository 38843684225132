import { useEffect, useState } from "react";
import useSale from "../useSale"; // Adjust the import path accordingly
import { ethers } from "ethers";

interface SaleData {
  price: string | null;
  deadline: number | null;
}

export const useFetchSaleData = (saleAddress: string | undefined) => {
  const [saleData, setSaleData] = useState<SaleData>({
    price: null,
    deadline: null,
  });
  const [loadingSale, setLoading] = useState<boolean>(true);
  const [errorSale, setError] = useState<Error | null>(null);
  const { ReadSalePrice, DeadlineSale } = useSale();

  useEffect(() => {
    const fetchSaleData = async () => {
      try {
        const [priceBigint, deadline] = await Promise.all([
          ReadSalePrice(saleAddress),
          DeadlineSale(saleAddress),
        ]);

        const priceWeiBigNumber = ethers.BigNumber.from(priceBigint);
        const priceInEther = ethers.utils.formatEther(priceWeiBigNumber);
        console.log("priceInEther",priceInEther)

        setSaleData({
          price: priceInEther,
          deadline: Number(deadline),
        });
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchSaleData();
  }, [ReadSalePrice, DeadlineSale, saleAddress]);

  return { saleData, loadingSale, errorSale };
};

export default useFetchSaleData;
