import { useEffect, useState } from "react";
import { Account, Product } from "../../interface/data";
import { useAccount } from "wagmi";
import { db } from "../../firebaseConfig";

const useProfil = () => {
  const [account, setAccount] = useState<Account | null>(null);
  const [productsSell, setProductsSell] = useState<Product[]>([]);
  const [productsBuy, setProductsBuy] = useState<Product[]>([]);
  const { address } = useAccount();

  // Function to fetch products by IDs
  const fetchProductsByIds = async (productIds: string[]) => {
    const productsPromises = productIds.map(async (id) => {
      const docRef = db.collection("products").doc(id);
      const doc = await docRef.get();
      return doc.exists ? ({ productId: id, ...doc.data() } as Product) : null;
    });
    return Promise.all(productsPromises);
  };

  useEffect(() => {
    const fetchAccountData = async () => {
      if (address) {
        const accountRef = db.collection("accounts").doc(address);
        const doc = await accountRef.get();
        if (doc.exists) {
          const accountData = doc.data() as Account;
          setAccount(accountData);

          const buyProducts = accountData.buy
            ? await fetchProductsByIds(accountData.buy)
            : [];
          const sellProducts = accountData.sell
            ? await fetchProductsByIds(accountData.sell)
            : [];
          setProductsSell(
            [...sellProducts].filter((p): p is Product => p !== null)
          );

          setProductsBuy(
            [...buyProducts].filter((p): p is Product => p !== null)
          );
        }
      }
    };

    fetchAccountData();
  }, [account]);

  return {
    account,
    productsSell,
    productsBuy,
  };
};

export default useProfil;
