import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCTVCRTIoFoPhfkkKW4rBSMU_uXFShSH9k",
  authDomain: "testnewworld-18b4b.firebaseapp.com",
  projectId: "testnewworld-18b4b",
  storageBucket: "testnewworld-18b4b.appspot.com",
  messagingSenderId: "917249504691",
  appId: "1:917249504691:web:35f2e0a2df5f044c6e47c6"
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

const db = firebase.firestore();

export { db };
