import { useState, useEffect } from 'react';
import {
  prepareWriteContract,
  waitForTransaction,
  writeContract,
  readContract,
} from "@wagmi/core";
import axios from 'axios';
import { ethers } from 'ethers';
import ERC20ABI from "../../contract/abi/erc20.json";
import React, { useCallback } from "react";


const Erc20Address = "0x9470e1E043E87c3e74820b6da120e730D8d850Df";
const stakingAddress = "0x09127fD097a689105A63a04FE526a22d871C91Cd";


const useErc20Token = () => {
  

    const BalanceOf = useCallback(
      async (AddressHolder: string | undefined) => {
        try {
          const BalanceOf = await readContract({
            address: Erc20Address as `0x${string}`,
            abi: ERC20ABI,
            functionName: "balanceOf",
            args: [AddressHolder],
          });
          const balanceinWei = ethers.BigNumber.from(BalanceOf);
          const BalanceInEther = ethers.utils.formatEther(balanceinWei);
  
          console.log("BalanceInEther",BalanceInEther)
          return BalanceInEther as string;
        } catch (error) {
          console.error("Error reading StartingPrice from contract", error);
          throw error;
        }
      },
      []
    );

    const Approve = async () => {   
      try {
        const amount = "50000000000000000000000000";

        const { request } = await prepareWriteContract({
          address: Erc20Address,
          abi: ERC20ABI,
          functionName: "approve",
          args: [stakingAddress,amount],
        });
        const { hash } = await writeContract(request);
  
        const data = await waitForTransaction({
          hash,
        });
  
        console.log(`Transaction data:`, data.logs[0].address);
        console.log(data);
  
      
        return data;
      } catch (error) {
        console.error("Transaction failed", error);
        throw error;
      }
    };

    const CheckApproval = async (userAddress: string) => {
      try {
        const allowance = await readContract({
          address: Erc20Address, 
          abi: ERC20ABI, 
          functionName: 'allowance',
          args: [userAddress, stakingAddress],
        });
    
       
        const minApproval = "500000000000000000000000";
    
        return ethers.BigNumber.from(allowance).gte(minApproval);
      } catch (error) {
        console.error("Error checking approval", error);
        throw error;
      }
    };
  
  
    return {
      BalanceOf,
      Approve,
      CheckApproval
    };
  };
  
  export default useErc20Token;