import firebase from "firebase/app";
import "firebase/firestore";

export enum ProductType {
  Auction = "AUCTION",
  Sale = "SALE",
}

interface BaseProduct {
  productId?: string;
  title?: string;
  description?: string;
  postageOptions?: string[];
  categorie?: string;
  image?: string;
  contract?: string;
  hasPaid?: boolean;
  buyer?: string;
}

export interface AuctionProduct extends BaseProduct {
  type: ProductType.Auction;
  data: Auction;
}

export interface SaleProduct extends BaseProduct {
  type: ProductType.Sale;
  data: Sale;
}

export type Product = AuctionProduct | SaleProduct;

export interface Auction {
  highestBidder?: string;
  seller?: string;
  highestBid?: number;
  deadline?: firebase.firestore.Timestamp;
  startingPrice: number;
  confirmed?: boolean;
  transactionFinished?: boolean;
}

export interface Sale {
  seller?: string;
  price?: number;
  saleTime?: number;
  deadline?: firebase.firestore.Timestamp;
  confirmed?: boolean;
  transactionFinished?: boolean;
}

export interface Review {
  depositor: Account;
  message: string;
  stars: number;
}

export interface Account {
  address?: string;
  name?: string;
  buy?: string[];
  sell?: string[];
  reviews?: Review[];
}

// Define the structure of a message in the chat
export interface Message {
  sender?: string; // Address of the sender
  recipient?: string; // Address of the recipient
  timestamp: firebase.firestore.Timestamp; // When the message was sent
  content: string; // The message content
}

// Define the structure of a chat session
export interface ChatSession {
  buyer?: string;
  seller?: string;
  messages: Message[];
}
