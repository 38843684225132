import React, { useState, useCallback } from "react";
import ProductPage from "./product/ProductPage";
import Categories from "../components/CategoryBlock";
import { Link } from "react-router-dom";
import useFactory from "../hook/web3/useFactory";
import useSale from "../hook/web3/useSale";
import useAuction from "../hook/web3/useAuction";

export default function Index() {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  
  return (
    <div className="p-0 m-0">
      <div className="flex m-0 p-0">
      </div>
    </div>
  );
}
