import React from "react";
import { ProductType } from "../../interface/data";
import usePaidProducts from "../../hook/web2/usePaidProducts";
import { Link } from "react-router-dom";

const Leaderboard: React.FC = () => {
  const products = usePaidProducts();
  const sortedProducts = [...products].sort((a, b) => {
    const bidA =
      a.type === ProductType.Auction
        ? a.data.highestBid || 0
        : a.data.price || 0;
    const bidB =
      b.type === ProductType.Auction
        ? b.data.highestBid || 0
        : b.data.price || 0;
    return bidB - bidA; // should return a number
  });

  return (
  <div className="container mx-auto mt-10 p-4 bg-gradient-to-br from-gray-200 to-gray-300 border border-gray-400 shadow-xl">
      <h2 className="text-2xl font-bold text-center text-gray-700 mb-6">
        Product Leaderboard
      </h2>
      <table className="w-full bg-gray-100 border-collapse border border-gray-400">
        <thead>
          <tr className="bg-gray-300">
            <th className="py-2 px-4 border-b border-gray-400">Seller</th>
            <th className="py-2 px-4 border-b border-gray-400">Title</th>
            <th className="py-2 px-4 border-b border-gray-400">Category</th>
            <th className="py-2 px-4 border-b border-gray-400">Bid/Price</th>
          </tr>
        </thead>
        <tbody>
          {sortedProducts.map((product, index) => (
            <tr
              key={product.productId}
              className={`${index % 2 === 0 ? "bg-gray-200" : "bg-white"}`}
            >
              <td className="py-2 px-4 border-b border-gray-400 hover:text-blue-100">
                <Link to={`/publicAccount/${product.data.seller}`}>
                  {product.data.seller}
                </Link>
              </td>
              <td className="py-2 px-4 border-b border-gray-400">
                {product.title}
              </td>
              <td className="py-2 px-4 border-b border-gray-400">
                {product.categorie}
              </td>
              <td className="py-2 px-4 border-b border-gray-400">
                {product.type === ProductType.Auction
                  ? product.data.highestBid
                  : product.data.price}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Leaderboard;
