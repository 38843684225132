import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAccount } from "wagmi";
import { db } from "../../firebaseConfig";
import { ChatSession, Message } from "../../interface/data";
import firebase from "firebase";

const ChatPage: React.FC = () => {
  const { recipientAddress } = useParams<{ recipientAddress: string }>();
  const { address: senderAddress } = useAccount();
  const [chatSession, setChatSession] = useState<ChatSession | null>(null);
  const [newMessage, setNewMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  // Generate a unique chatId from the sender and recipient addresses
  const chatId = React.useMemo(() => {
    const ids = [senderAddress, recipientAddress].sort();
    return `${ids[0]}_${ids[1]}`;
  }, [senderAddress, recipientAddress]);

  useEffect(() => {
    const fetchChatSession = async () => {
      try {
        const chatRef = db.collection("chats").doc(chatId);
        const doc = await chatRef.get();

        if (doc.exists) {
          setChatSession(doc.data() as ChatSession);
        } else {
          setChatSession({
            messages: [],
          });
        }
      } catch (error) {
        console.error("Error fetching chat session:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchChatSession();
  }, [chatId]);

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return; // Don't send empty messages

    const message: Message = {
      sender: senderAddress,
      recipient: recipientAddress,
      timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
      content: newMessage,
    };

    try {
      const chatRef = db.collection("chats").doc(chatId);

      await db.runTransaction(async (transaction) => {
        const chatDoc = await transaction.get(chatRef);

        if (!chatDoc.exists) {
          // If the chat doesn't exist, create it with the new message
          transaction.set(chatRef, {
            messages: [message],
          });
        } else {
          // If the chat exists, append the new message
          const existingMessages = chatDoc.data()?.messages || [];
          transaction.update(chatRef, {
            messages: [...existingMessages, message],
          });
        }
      });

      setChatSession((prevSession) => ({
        ...prevSession,
        messages: [...(prevSession?.messages || []), message],
      }));
      setNewMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  if (loading) {
    return <div className="bg-gray-300 text-black p-2">Loading chat...</div>;
  }

  return (
    <div className="p-4 bg-gray-100">
      <h1 className="text-2xl font-bold mb-4 text-gray-700">
        Chat with {recipientAddress}
      </h1>
      <div className="h-96 overflow-y-auto border border-gray-300 p-2 bg-white">
        {chatSession?.messages.map((msg, idx) => (
          <div
            key={idx}
            className={`flex ${
              msg.sender === senderAddress ? "justify-start" : "justify-end"
            } my-2`}
          >
            <span className="bg-gray-200 p-2 rounded-lg">
              {msg.content}
              <p className="text-xs text-gray-600">
                Sent: {msg.timestamp.toDate().toLocaleString()}
              </p>
            </span>
          </div>
        ))}
      </div>
      <textarea
        className="w-full h-24 mt-2 p-2 border border-gray-300 bg-white"
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        placeholder="Type your message here..."
      />
      <button
        className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded my-2"
        onClick={handleSendMessage}
      >
        Send
      </button>
    </div>
  );
};

export default ChatPage;
