import { useState, useEffect } from 'react';
import {
  prepareWriteContract,
  waitForTransaction,
  writeContract,
  readContract,
} from "@wagmi/core";
import axios from 'axios';
import { ethers } from 'ethers';
import ERC20ABI from "../../contract/abi/erc20.json";
import React, { useCallback } from "react";


interface marketCapData {
  formattedmarketCap: number | null;
  error: string | null;
}
interface TokenPriceData {
  price: number | null;
  errorr: string | null;
}

interface TransferData {
  transfers: { value: string }[];
}

interface TransfersHookData {
  transfers: TransferData[] | null;
  error: string | null;
}



export const useDexscreenerMarketcap = (pairId: string): marketCapData => {
  const [data, setData] = useState<marketCapData>({ formattedmarketCap: null, error: null });

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await axios.get(`https://api.dexscreener.com/latest/dex/search?q=${pairId}`);
        if (response.data && response.data.pairs.length > 0) {
          const marketCap = response.data.pairs[0].fdv; 
          const formattedmarketCap = marketCap.toLocaleString();
          setData({ formattedmarketCap, error: null });
        } else {
          throw new Error('No pair data found');
        }
      } catch (error) {
        setData({ formattedmarketCap: null, error: error instanceof Error ? error.message : String(error) });
      }
    };

    fetchPrice();
  }, [pairId]);

  

  return data;
};


export const useDexscreenerPrice = (pairId: string): TokenPriceData => {
  const [data, setData] = useState<TokenPriceData>({ price: null, errorr: null });

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await axios.get(`https://api.dexscreener.com/latest/dex/search?q=${pairId}`);
        if (response.data && response.data.pairs.length > 0) {
          const price = response.data.pairs[0].priceUsd; 
        
          setData({ price, errorr: null });
        } else {
          throw new Error('No pair data found');
        }
      } catch (errorr) {
        setData({ price: null, errorr: errorr instanceof Error ? errorr.message : String(errorr) });
      }
    };

    fetchPrice();
  }, [pairId]);

  

  return data;
};


