import React, { useState } from "react";
import { useAccount } from "wagmi";
import { Account, Product, ProductType } from "../../interface/data";
import { db } from "../../firebaseConfig";
import firebase from "firebase";
import useFactory from "../../hook/web3/useFactory";
import useAuction from "../../hook/web3/useAuction";
import { ethers } from "ethers";
import { parseEther, parseGwei } from "viem";
import defaultImage from "./Missing.png";

const categories = [
  "Crypto Services",
    "NFTs",
    "Hacking",
    "Digital Goods",
    "Subscriptions",
    "Electronics",
    "Medicine/Nootropics",
    "Erotica / XXX",
    "Others",
];

const noPostageCategories = new Set([
  "Crypto Services",
  "Hacking",
  "Subscriptions",
  "NFTs",
  "Developers",
  "Designers",
  "Marketing",
  "Bots",
]);

const ProductForm: React.FC = () => {
  const [category, setCategory] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [postageOptions, setPostageOptions] = useState<string>("");
  const [image, setImage] = useState<string | null>(defaultImage);
  const [productType, setProductType] = useState<string>("");
  const [startingPrice, setStartingPrice] = useState<string>("");
  const [deadline, setDeadline] = useState<Date>(new Date());
  const [price, setPrice] = useState<string>("");
  const { createAuction, createSale } = useFactory();
  const { StartingPrice } = useAuction();

  const { address } = useAccount();

  function getSecondsUntilDeadline(deadline: Date): number {
    const now = new Date();
    const timeDiff = deadline.getTime() - now.getTime(); // difference in milliseconds
    const secondsDiff = timeDiff / 1000; // convert milliseconds to seconds
    return Math.floor(secondsDiff);
  }

  const showPostageOptions = !noPostageCategories.has(category);

  const canSubmit = () => {
    // Check if all the required fields are filled in
    console.log(Number(startingPrice));
    return (
      title.trim() !== "" &&
      description.trim() !== "" &&
      category !== "" &&
      productType !== "" &&
      (productType === "Auction"
        ? Number(startingPrice) > 0 && deadline
        : Number(price) > 0)
    );
  };

  const handleSubmit = async () => {
    let product: Product = {} as Product;

    try {
      if (!canSubmit()) {
        console.log("Please fill in all the fields.");
        alert("Please fill in all the fields.");
        return;
      }

      if (productType === "Auction") {
        if (getSecondsUntilDeadline(deadline) < 0) {
          console.log("The deadline must be in the future.");
          alert("The deadline must be in the future.");
          return;
        }

        const contractGet: string = await createAuction(
          getSecondsUntilDeadline(deadline),
          startingPrice
        );
        console.log("contractGet",contractGet)

        const web3StartingPrice = await StartingPrice(contractGet);
        try {
          product = {
            title: title,
            description: description,
            postageOptions: [postageOptions],
            categorie: category,
            image: image!,
            contract: contractGet,
            type: ProductType.Auction,
            hasPaid: false,
            buyer: "",
            data: {
              seller: address,
              startingPrice: Number(web3StartingPrice),
              confirmed: false,
              deadline: firebase.firestore.Timestamp.fromDate(deadline),
            },
          };

          console.log(product);

          try {
            await db.runTransaction(async (transaction) => {
              // Create document references
              const productRef = db.collection("products").doc();
              const accountRef = db.collection("accounts").doc(address);

              console.log("Starting transaction for address:", address);

              // Attempt to get the account snapshot
              const accountSnap = await transaction.get(accountRef);
              console.log(
                `Account data exists for address ${address}:`,
                accountSnap.exists
              );

              // Set product data
              transaction.set(productRef, product);
              console.log(
                "Product set with ID:",
                productRef.id,
                "and data:",
                product
              );

              // Handle account update or creation
              if (accountSnap.exists) {
                const accountData = accountSnap.data();
                console.log("Existing account data:", accountData);

                const updatedSellList = accountData?.sell
                  ? [...accountData.sell, productRef.id]
                  : [productRef.id];

                transaction.update(accountRef, { sell: updatedSellList });
                console.log(
                  "Account updated with new sell list:",
                  updatedSellList
                );
              } else {
                const newAccount = {
                  address: address,
                  sell: [productRef.id],
                };

                transaction.set(accountRef, newAccount);
                console.log("New account created with data:", newAccount);
              }
            });

            console.log("Transaction successfully committed!");
            alert("Add product success.");
          } catch (e) {
            console.error("Transaction failed: ", e);
            alert("An error occurred, please check the logs for more details");
          }
        } catch (e) {
          console.log("error create product", e);
        }
      }
    } catch (e) {
      console.log("error create auction", e);
    }

    if (productType === "Sale") {
      try {
        const contractGet: string = await createSale(price.toString());

        product = {
          title: title,
          description: description,
          postageOptions: [postageOptions],
          categorie: category,
          image: image!,
          contract: contractGet,
          type: ProductType.Sale,
          hasPaid: false,
          buyer: "",
          data: {
            seller: address,
            price: Number(price),
          },
        };

        console.log(product);

        try {
          await db.runTransaction(async (transaction) => {
            const productRef = db.collection("products").doc();
            const accountRef = db.collection("accounts").doc(address);

            const accountSnap = await transaction.get(accountRef);
            console.log(
              `Account data exists for address ${address}:`,
              accountSnap.exists
            );

            transaction.set(productRef, product);
            console.log(
              "Product set with ID:",
              productRef.id,
              "and data:",
              product
            );

            // Handle account update or creation
            if (accountSnap.exists) {
              const accountData = accountSnap.data();
              console.log("Existing account data:", accountData);

              const updatedSellList = accountData?.sell
                ? [...accountData.sell, productRef.id]
                : [productRef.id];

              transaction.update(accountRef, { sell: updatedSellList });
              console.log(
                "Account updated with new sell list:",
                updatedSellList
              );
            } else {
              const newAccount = {
                address: address,
                sell: [productRef.id],
              };

              transaction.set(accountRef, newAccount);
              console.log("New account created with data:", newAccount);
            }

            console.log("Transaction successfully committed!");

            console.log(
              "Product reference set and account updated in transaction."
            );
          });

          console.log("Transaction successfully committed!");
          alert("Add product success.");
        } catch (e) {
          console.error("Transaction failed: ", e);
          alert(
            "There was an error processing the transaction. Please try again."
          );
        }
      } catch (e) {
        console.error("Error creating sale: ", e);
        alert(
          "There was an error creating the sale. Please ensure you're logged in and try again."
        );
      }
    }
  };

  return (
    <div className="max-w-lg mx-auto mt-10 p-4 bg-gradient-to-br from-gray-200 to-gray-300 border border-gray-400 shadow-xl">
      <h2 className="text-2xl font-bold text-center text-gray-700 mb-6">
        Add Product
      </h2>

      <div className="flex flex-col space-y-4">
        <select
          className="border border-gray-400 bg-white p-2 rounded-md focus:outline-none"
          required
          onChange={(e) => setCategory(e.target.value)}
          value={category}
        >
          <option value="" disabled>
            Select Category
          </option>
          {categories.map((cat, idx) => (
            <option key={idx} value={cat}>
              {cat}
            </option>
          ))}
        </select>

        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />

        <textarea
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        ></textarea>

     {/*    {showPostageOptions && (
          <select
            required
            onChange={(e) => setPostageOptions(e.target.value)}
            value={postageOptions}
          >
            <option value="" disabled>
              Select Postage Options
            </option>
            {["DHL", "FedEx", "UPS"].map((option, idx) => (
              <option key={idx} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
 */}
        <input
          type="file"
          onChange={(e) => {
            const input = e.target;
            if (input.files && input.files.length > 0) {
              const file = input.files[0];

              const maxSizeInKB = 500;
              const maxSizeInBytes = maxSizeInKB * 1024;

              if (file.size > maxSizeInBytes) {
                alert(`The file size should not exceed ${maxSizeInKB} KB.`);
                input.value = ""; // Réinitialiser le champ de fichier
                return;
              }

              const reader = new FileReader();
              reader.onloadend = () => {
                setImage(reader.result as string);
              };
              reader.readAsDataURL(file);
            }
          }}
          required
        />

        <select
          required
          onChange={(e) => setProductType(e.target.value)}
          value={productType}
        >
          <option value="" disabled>
            Select Product Type
          </option>
          <option value="Auction">Auction</option> 
          <option value="Sale">Sale</option>
        </select>

        {productType === "Auction" && (
          <>
            <input
              type="text"
              placeholder="Starting Price"
              value={startingPrice}
              onChange={(e) => setStartingPrice(e.target.value)}
              required
            />
            <input
              type="datetime-local"
              placeholder="Deadline"
              value={new Date(deadline).toISOString().slice(0, 16)} // slice to get the correct format
              onChange={(e) => setDeadline(new Date(e.target.value))}
              required
              min={new Date().toISOString().slice(0, 16)} // slice to get the correct format
            />
          </>
        )}

        {productType === "Sale" && (
          <input
            type="text"
            placeholder="Price in ETH"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            required
          />
        )}

        <button
          className="bg-gray-400 text-white py-2 px-4 rounded-md hover:bg-gray-500 focus:outline-none"
          onClick={handleSubmit}
        >
          {productType === "Auction" ? "Create Auction" :  "Create Sale"}
        </button>
      </div>
    </div>
  );
};

export default ProductForm;
