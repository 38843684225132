import { useCart } from "../../hook/CartContext";
import React, { useState, useEffect } from "react";
import { Account, Product, ProductType } from "../../interface/data";
import { useAccount } from "wagmi";
import { db } from "../../firebaseConfig";
import useSale from "../../hook/web3/useSale";
import { Link } from "react-router-dom";

const CartPage: React.FC = () => {
  const { cart } = useCart();
  const { address } = useAccount();
  const [salePrice, setSalePrice] = useState(null);
  const { deleteCart } = useCart();

  const { BuySale } = useSale();
 

const payProduct = async (product: Product, address: string) => {
  try {
      await BuySale(product.contract);
      await deleteCart(product);
      await db.runTransaction(async (transaction) => {
          const productRef = db.collection("products").doc(product.productId);
          const accountRef = db.collection("accounts").doc(address);

          
          const productDoc = await transaction.get(productRef);
          const accountDoc = await transaction.get(accountRef);

          if (!productDoc.exists) {
              throw new Error("Product document does not exist!");
          }

          let accountData;

          if (!accountDoc.exists) {
            
              accountData = { address: address, buy: [product.productId] };
              
              transaction.set(accountRef, accountData);
              console.log("New account created for address:", address);
          } else {
             
              accountData = accountDoc.data() as Account;
              const updatedBuyList = accountData.buy
                  ? [...accountData.buy, product.productId]
                  : [product.productId];
              transaction.update(accountRef, { buy: updatedBuyList });
          }

          transaction.update(productRef, { hasPaid: true, buyer: address });
      });

      console.log("Product paid successfully and added to your purchases");
      alert("Product purchased, you can contact the seller using the chat.");
  } catch (e) {
      console.error("Transaction failed: ", e);
  }
};

  return (
    <div className="bg-gray-100 p-4 rounded-md shadow">
      <h2 className="text-2xl font-bold text-gray-800 border-b border-gray-300 pb-2 mb-4">
        Your Cart
      </h2>
      <div className="space-y-4">
        {cart.map((product: Product) => (
          <div
            key={product.productId}
            className="flex items-center bg-white p-4 rounded-md border border-gray-300"
          >
            <img
              className="w-24 h-24 object-cover mr-4"
              src={product.image}
              alt={product.title}
            />
            <div className="flex-1">
              <h3 className="text-lg font-semibold text-gray-700">
                {product.title}
              </h3>
              {product.type === ProductType.Auction && (
                <p className="text-gray-600">
                  Highest bid: {product.data.highestBid}
                </p>
              )}
              {product.type === ProductType.Sale && (
                <p className="text-gray-600">Price: {product.data.price} ETH</p>
              )}
            </div>
            <button
              className="px-4 py-2 bg-gray-200 text-gray-700 hover:bg-gray-300 rounded transition duration-300 ease-in-out"
              onClick={() => payProduct(product, address as  `0x${string}`)}
            >
              Pay
            </button>

            <button
              className="px-4 py-2 bg-red-200 text-white-700 rounded transition duration-300 ease-in-out"
              onClick={() => deleteCart(product)}
            >
              x
            </button>
            <Link
              to={`/chat/${product.data.seller}`}
              className="text-gray-600 hover:text-gray-700"
            >
              💬
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CartPage;
