import {
  prepareWriteContract,
  waitForTransaction,
  writeContract,
  readContract,
} from "@wagmi/core";
import React, { useCallback } from "react";
import saleABI from "../../contract/abi/Sale.json";
import { BigNumber, ethers } from "ethers";
import { parseEther, parseGwei } from "viem";

const useSale = () => {
  const ReadSalePrice = useCallback(async (saleAddress: string | undefined) => {
    try {
      const priceBigint = await readContract({
        address: saleAddress as `0x${string}`,
        abi: saleABI,
        functionName: "price",
      });
       
      console.log("price:", priceBigint);
      return priceBigint as string;
    } catch (error) {
      console.error("Error reading price from contract", error);
      throw error;
    }
  }, []);

  const DeadlineSale = useCallback(async (saleAddress: string | undefined) => {
    try {
      const deadline = await readContract({
        address: saleAddress as `0x${string}`,
        abi: saleABI,
        functionName: "deadline",
      });

      const deadlineInMilliseconds = Number(deadline) * 1000;
   
      const deadlineDate = new Date(deadlineInMilliseconds);


      console.log("deadline:", deadline);
      return deadlineDate;
    } catch (error) {
      console.error("Error reading price from contract", error);
      throw error;
    }
  }, []);

  const BuySale = useCallback(async (saleAddress: string | undefined) => {
    try {
      const priceBigint = await ReadSalePrice(saleAddress);
      const priceWeiBigNumber = ethers.BigNumber.from(priceBigint);
      const priceInEther = ethers.utils.formatEther(priceWeiBigNumber);

      console.log("priceInEther", priceInEther);

      const { request } = await prepareWriteContract({
        address: saleAddress as `0x${string}`,
        abi: saleABI,
        functionName: "buy",
        value: parseEther(priceInEther),
      });
      const { hash } = await writeContract(request);

      const data = await waitForTransaction({
        hash,
      });

      console.log("Tx hash");
      return data;
    } catch (error) {
      console.error("Error BuySale from contract", error);
      throw error;
    }
  }, []);

  const BuyerConfirm = async (saleAddress: string | undefined) => {
    try {
      const { request } = await prepareWriteContract({
        address: saleAddress as `0x${string}`,
        abi: saleABI,
        functionName: "buyerConfirms",
      });
      const { hash } = await writeContract(request);

      const data = await waitForTransaction({
        hash,
      });

      console.log("Tx hash", data);
      return data;
    } catch (error) {
      console.error("Error BuyerConfirm from contract", error);
      throw error;
    }
  };

  const endSale = async (saleAddress: string | undefined) => {
    try {
      const deadlineDate = await DeadlineSale(saleAddress);
      const now = new Date(); 
  
    
      if (deadlineDate > now) {
        
        alert('You must wait for the deadline to pass before ending the sale.');
      } else {
        
        const { request } = await prepareWriteContract({
          address: saleAddress as `0x${string}`,
          abi: saleABI,
          functionName: "saleEnd",
        });
        
        const { hash } = await writeContract(request);
  
        const data = await waitForTransaction({
          hash,
        });
  
        console.log("Tx hash", data);
        return data;
      }
    } catch (error) {
      console.error("Error ending sale from contract", error);
      throw error;
    }
  };
  

  return { ReadSalePrice, DeadlineSale, BuySale, BuyerConfirm, endSale };
};

export default useSale;
