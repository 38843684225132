import "./App.css";
import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import Staking from "./components/Staking";

import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet } from "wagmi/chains";

import ProductPage from "./pages/product/ProductPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import index from "./pages";
import ProductDetailsPage from "./pages/product/ProductDetailsPage";
import { CartProvider } from "./hook/CartContext";
import CartPage from "./pages/cart/Cart";
import Leaderboard from "./pages/leaderboard/leaderboard";
import AccountPage from "./pages/account/account";
import ProductForm from "./pages/postData/ProductForm";
import PublicProfile from "./pages/account/profilPublic";
import ChatPage from "./pages/chat/ChatPage";
import Chat from "./pages/chat/Chat";
import RevenueShareDashboard from "./components/RevenueShareDashboard";


const chains = [mainnet];
const projectId = "4e94c20e6132bf42c55ee4f6793e9f9d";

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

function App() {
  return (
    <div className="App">
      <WagmiConfig config={wagmiConfig}>
        <CartProvider>
          <BrowserRouter>
            <Navbar />

            <Routes>
              <Route
                path="/product/:productId"
                Component={ProductDetailsPage}
              />
              <Route
                path="/publicAccount/:address_"
                Component={PublicProfile}
              />
              <Route path="/cart" Component={CartPage} />
              <Route path="/leaderboard" Component={Leaderboard} />
              <Route path="/account" Component={AccountPage} />
              <Route path="/form" Component={ProductForm} />
              <Route path="/chat" Component={Chat} />
              <Route path="/chat/:recipientAddress" Component={ChatPage} />
              <Route  path="/Staking"
               Component={Staking} />
              <Route  path="/RevenueShareDashboard"
               Component={RevenueShareDashboard}
                
              />

              <Route path="/" Component={index} />
            </Routes>
          </BrowserRouter>
        </CartProvider>
      </WagmiConfig>

      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </div>
  );
}

export default App;
