import React from 'react';

const ScrollingImagesBanner: React.FC = () => {
  const styles = `
    @keyframes marquee {
      from { transform: translateX(0); }
      to { transform: translateX(-100%); } 
    }

    .marquee-container {
      overflow: hidden;
      position: relative;
      height: 100px; 
      display: flex;
      align-items: center;
    }

    .marquee-content {
      display: flex;
      animation: marquee 7.5s linear infinite;
    }

    .marquee-image {
      width: auto; 
      height: 100%;
      object-fit: cover; 
    }

    @media (max-width: 768px) {
      .marquee-container {
        height: 50px; 
      }
      .marquee-content {
        animation: marquee 5s linear infinite;
      }
      .marquee-image {
        min-width: 100%; 
      }
    }
  `;

  return (
    <div className="marquee-container bg-transparent">
      <style dangerouslySetInnerHTML={{ __html: styles }} />
      <div className="marquee-content">
        <img src="/banner22.jpg" alt="Banner Image" className="marquee-image" />
        <img src="/banner22.jpg" alt="Banner Image" className="marquee-image" />
       
      </div>
    </div>
  );
};

export default ScrollingImagesBanner;
