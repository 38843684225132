import {
    prepareWriteContract,
    waitForTransaction,
    writeContract,
    readContract,
  } from "@wagmi/core";
  import React, { useCallback } from "react";
  import RevABI from "../../contract/abi/RevSharing.json";
  import { BigNumber, ethers } from "ethers";
  import { parseEther, parseGwei } from "viem";
  
  const useRev = () => {

    const RevAddres = "0xD0C9313Bdc255dC377043D1f197bB42d313fcdea";
  
    const ClaimReward = async () => {
        try {
          const { request } = await prepareWriteContract({
            address: RevAddres,
            abi: RevABI,
            functionName: "redeem",
          });
          const { hash } = await writeContract(request);
    
          const data = await waitForTransaction({
            hash,
          });
    
          console.log(`Transaction data:`, data.logs[0].address);
          console.log(data);
    
          const saleAddress = data.logs[0].address;
          return saleAddress;
        } catch (error) {
          console.error("Transaction failed", error);
          throw error;
        }
      };

      const PendingReward = useCallback(
        async (AddressHolder: string | undefined) => {
          try {
            const pendingReward = await readContract({
              address: RevAddres,
              abi: RevABI,
              functionName: "holding",
              args: [AddressHolder],
            });

            const priceWeiBigNumber = ethers.BigNumber.from(pendingReward);
            const priceInEther = ethers.utils.formatEther(priceWeiBigNumber);
    
            console.log("pendingReward:", priceInEther);
            return priceInEther as string;
          } catch (error) {
            console.error("Error reading pendingReward from contract", error);
            throw error;
          }
        },
        []
      );


    
  
    return {
    ClaimReward,
    PendingReward
    };
  };
  
  export default useRev;
  