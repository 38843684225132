import React, { useState, useEffect } from 'react';
import { useDexscreenerMarketcap, useDexscreenerPrice } from '../hook/web3/useDashboard'; 
import useRev from '../hook/web3/useRev';
import { useAccount } from "wagmi";
import erc20Token from '../hook/web3/useErc20';

const RevenueShareDashboard: React.FC = () => {
  const [reward, setReward] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  
  const tokenPair = "0x7e2971c3b22718a1EE5405C2503914FEEC7ABF89";
  const { address } = useAccount();
  const { formattedmarketCap, error: marketCapError } = useDexscreenerMarketcap(tokenPair);
  const { price } = useDexscreenerPrice(tokenPair);
  const { ClaimReward, PendingReward } = useRev();
  const { BalanceOf } = erc20Token();
  const [balance, setBalance] = useState<string | null>(null);

  useEffect(() => {
    const fetchReward = async () => {
      try {
        const pendingReward = await PendingReward(address);
        setReward(pendingReward);
      } catch (error) {
        console.error('Error fetching pending reward:', error);
        setReward(null);
      } finally {
        setLoading(false);
      }
    };

    if (address) {
      fetchReward();
    }
  }, [address, PendingReward]);
  
  useEffect(() => {
    const fetchBalance = async () => {
      if (address) {
        try {
          const tokenBalance = await BalanceOf(address);
          setBalance(tokenBalance); // Supposing tokenBalance is already formatted as a string
        } catch (error) {
          console.error('Error fetching token balance:', error);
          setBalance(null);
        }
      }
    };

    fetchBalance();
  }, [address, BalanceOf]);


  if (!address) {
    return (
      <div className="bg-gray-200 p-4 min-h-screen">
        <div className="container mx-auto">
          <div className="bg-gray-300 p-6 rounded-lg shadow-md">
            <p className="text-gray-700 text-xl">Please connect your wallet.</p>
          </div>
        </div>
      </div>
    );
  }
   return (
    <div className="bg-white p-6 min-h-screen flex items-start justify-center font-sans" style={{ paddingTop: '10vh' }}> 
      <div className="bg-white border-4 border-gray-800 rounded-lg shadow-xl p-6 w-full max-w-6xl space-y-6">
        <h2 className="text-3xl text-gray-800 font-bold mb-6 border-b-4 border-gray-800 pb-3">Revenue Share Dashboard</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {/* Individual stat cards */}
          <div className="bg-gray-200 border-2 border-gray-800 rounded-lg shadow p-4 text-center">
            <p className="text-gray-800 text-lg">Price</p>
            <p className="text-lg font-bold">${price}</p>
          
          </div>
          <div className="bg-gray-200 border-2 border-gray-800 rounded-lg shadow p-4 text-center">
            <p className="text-gray-800 text-lg">Market Cap</p>
            <p className="text-lg font-bold">${formattedmarketCap}</p>
        
          </div>
          <div className="bg-gray-200 border-2 border-gray-800 rounded-lg shadow p-4 text-center">
            <p className="text-gray-800 text-lg">Total volume</p>
            <p className="text-lg font-bold">$2,410,752</p>
           
          </div>
          <div className="bg-gray-200 border-2 border-gray-800 rounded-lg shadow p-4 text-center">
            <p className="text-gray-800 text-lg">Total rewards distributed</p>
            <p className="text-lg font-bold">$54,683.82</p>
           
          </div>
        </div>

        <div className="flex flex-col lg:flex-row justify-between gap-6">
          <div className="flex-1">
            <p className="mb-6 text-gray-800 text-lg">
              Holders can connect using this dashboard to view and claim their revenue share rewards. To qualify, a minimum balance of 250,000 $TNR is required.
            </p>
            <div className="mb-4" role="alert">
              <img src="/logo.png" alt="Logo" className="block mx-auto w-16 h-16" />
            </div>
          </div>
          <div className="flex-1 bg-gray-200 border-2 border-gray-800 rounded-lg p-6">
          <h3 className="text-xl text-gray-800 font-semibold mb-4">Token Holdings</h3>
      <div className="mb-6">
        <label className="block text-gray-800 text-lg font-bold mb-3">WALLET:</label>
        <div className="px-4 py-3 bg-white border-2 border-gray-800 rounded text-gray-800 leading-tight">
          {balance ? `${balance} TNR` : 'Calculating...'} 
        </div>
      </div>
            
            
            <div className="mb-6">
          <label className="block text-gray-800 text-lg font-bold mb-3">PENDING REWARD:</label>
          <div className="px-4 py-3 bg-white border-2 border-gray-800 rounded text-gray-800 leading-tight">
          {reward ? `${reward} ETH` : 'Calculating...'}
          </div>
        </div>
        <button 
          className="w-full bg-gray-800 hover:bg-gray-600 text-white font-bold py-3 px-6 rounded focus:outline-none focus:shadow-outline"
          onClick={ClaimReward} 
        >
          Claim
        </button>
      </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueShareDashboard;