import { useEffect, useState } from "react";
import { readContract } from "@wagmi/core";
import { BigNumber, ethers } from "ethers";
import useAuction from "../useAuction";

interface AuctionData {
  startingPrice: string | null;
  highestBid: string | null;
  highestBidder: string | null;
}

const useFetchAuctionData = (AA?: string) => {
  const { HighestBidder, HighestBid, StartingPrice } = useAuction();
  const [auctionData, setAuctionData] = useState<AuctionData>();
  const [loadingAuction, setLoading] = useState(true);
  const [errorAuction, setError] = useState<Error | null>(null);

  const fetchData = async (AuctionAddress: string | undefined) => {
    setLoading(true);
    try {
      const [startingPrice, highestBidder, highestBid] = await Promise.all([
        StartingPrice(AuctionAddress),
        HighestBidder(AuctionAddress),
        HighestBid(AuctionAddress),
      ]);

      console.log("highestBid", highestBid);

      console.log("startingPrice", startingPrice);

      setAuctionData({
        startingPrice: startingPrice,
        highestBid: highestBid,
        highestBidder: highestBidder,
      });
      return {
        startingPrice: startingPrice,
        highestBid: highestBid,
        highestBidder: highestBidder,
      };
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (AA) {
      fetchData(AA);
    }
  }, [StartingPrice, HighestBidder, HighestBid]);

  return { fetchData, auctionData, loadingAuction, errorAuction };
};

export default useFetchAuctionData;
