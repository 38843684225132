import {
  prepareWriteContract,
  waitForTransaction,
  writeContract,
} from "@wagmi/core";
import React, { useCallback } from "react";
import factoryABI from "../../contract/abi/AuctionFactory.json";
import { ethers } from "ethers";

const FactoryAddress = "0x990D24096f9c074Be14567BdAE5d4DDDb01a5765";

const useFactory = () => {
  const createSale = async (price: string) => {
    const PriceInEth = ethers.utils.parseEther(price);
    try {
      const { request } = await prepareWriteContract({
        address: FactoryAddress,
        abi: factoryABI,
        functionName: "createSale",
        args: [PriceInEth],
      });
      const { hash } = await writeContract(request);

      const data = await waitForTransaction({
        hash,
      });

      console.log(`Transaction data:`, data.logs[0].address);
      console.log(data);

      const saleAddress = data.logs[0].address;
      return saleAddress;
    } catch (error) {
      console.error("Transaction failed", error);
      throw error;
    }
  };

  const createAuction = async (duration: number, price: string) => {
    const PriceInEth = ethers.utils.parseEther(price);
    console.log(duration);
    console.log(price);

    try {
      const { request } = await prepareWriteContract({
        address: FactoryAddress,
        abi: factoryABI,
        functionName: "createAuction",
        args: [duration, PriceInEth],
      });
      const { hash } = await writeContract(request);

      const data = await waitForTransaction({
        hash,
      });

      console.log(`Transaction data:`, data.logs[0].address);
      console.log(data);

      const AuctionAddress = data.logs[0].address;
      return AuctionAddress;
    } catch (error) {
      console.error("Transaction failed", error);
      throw error;
    }
  };

  return { createSale, createAuction };
};

export default useFactory;
