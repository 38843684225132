import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useCart } from "../../hook/CartContext";
import { ProductType } from "../../interface/data";
import useUnpaidProducts from "../../hook/web2/useUnpaidProducts";
import useAuction from "../../hook/web3/useAuction";
import { Account, Product } from "../../interface/data";
import { BigNumber, ethers } from "ethers";
import useFetchAuctionData from "../../hook/web3/AuctionData/useFetchAuctionData";
import { db } from "../../firebaseConfig";
import { useAccount } from "wagmi";

interface ProductItemProps {
  product: Product;
}

const HighestBidText: React.FC<ProductItemProps> = ({ product }) => {
  const { auctionData, loadingAuction, errorAuction } = useFetchAuctionData(
    product.contract
  );
  let content;

  if (product.type === ProductType.Auction) {
    if (loadingAuction) content = <p>Loading auction data...</p>;
    else if (errorAuction) content = <p>Error: {errorAuction.message}</p>;
    else {
      content = (
        <div>
          {product.title}
          <p>Higuest bid: {auctionData && auctionData.highestBid} ETH</p>
        </div>
      );
    }
  }
  return <p>{content}</p>;
};

const ProductDetailsPage: React.FC = () => {
  const { productId: productIdString } = useParams<{ productId: string }>();
  const unpaidProducts = useUnpaidProducts();
  const { address } = useAccount();

  const { addToCart } = useCart();
  const { Bid } = useAuction();
  const [bidAmount, setBidAmount] = useState<string>("");

  const handleBid = async (product: Product) => {
    const numericBidAmount = parseFloat(bidAmount);
    if (isNaN(numericBidAmount)) {
      console.error("Invalid bid amount");
      return;
    }

    const auctionAddress = product.contract;
    if (!auctionAddress) {
      console.error("Product does not have an auction address");
      return;
    }

    try {
      await Bid(auctionAddress, String(numericBidAmount));
      await db.runTransaction(async (transaction) => {
        const productRef = db.collection("products").doc(product.productId);
        const accountRef = db.collection("accounts").doc(address);

        const productDoc = await transaction.get(productRef);
        const accountDoc = await transaction.get(accountRef);

        if (!productDoc.exists) {
          throw new Error("Product document does not exist!");
        }

        let accountData;

        if (!accountDoc.exists) {
          accountData = { address: address, buy: [product.productId] };

          transaction.set(accountRef, accountData);
          console.log("New account created for address:", address);
        } else {
          accountData = accountDoc.data() as Account;
          const updatedBuyList = accountData.buy
            ? [...accountData.buy, product.productId]
            : [product.productId];
          transaction.update(accountRef, { buy: updatedBuyList });
        }

        transaction.update(productRef, { hasPaid: true, buyer: address });
      });

      console.log(
        `Bid of ${numericBidAmount} was successfully placed on product with auction address ${auctionAddress}`
      );
    } catch (error) {
      console.error("Error placing bid:", error);
    }
  };

  if (!productIdString) {
    return <div>Product not found</div>;
  }

  const productId = productIdString;
  const product = unpaidProducts.find((p) => p.productId === productId);

  if (!product) {
    return <div>Product not found</div>;
  }

  const addCart = () => {
    alert("You added to cart: " + product.title);
    addToCart(product);
  };

  return (
    <div className="container mx-auto px-4 mt-8 flex flex-col md:flex-row items-start">
      {" "}
      <img
        className="w-full max-w-md mx-auto md:mx-0"
        src={product.image}
        alt={product.title}
      />
      <div className="md:ml-8 mt-4 md:mt-0 flex flex-col">
        <h1 className="text-3xl font-bold mb-4">{product.title}</h1>
        <p className="text-gray-700 text-base mb-4">{product.description}</p>

        {product.type === ProductType.Auction && (
          <>
            <HighestBidText product={product} />
            <p className="text-gray-700 text-base mb-4">
              Starting Price: {product.data.startingPrice} ETH
            </p>
          </>
        )}

        {product.type === ProductType.Sale && (
          <>
            <p className="text-gray-700 text-base mb-4">
              Price: {product.data.price} ETH
            </p>
          </>
        )}

        <p className="text-gray-700 text-base mb-4 hover:text-blue-400">
          Seller:{" "}
          <Link to={`/publicAccount/${product.data.seller}`}>
            {product.data.seller}
          </Link>
        </p>
        <p className="text-gray-700 text-base mb-4 hover:text-blue-400">
          Categorie: {product.categorie}
        </p>
        {product.type === ProductType.Auction && product.data.deadline && (
          <p className="text-gray-700 text-base mb-4">
            Deadline:{" "}
            {new Date(product.data.deadline.toDate()).toLocaleString()}
          </p>
        )}

        {product.type === ProductType.Auction && (
          <div className="mt-4 flex items-center">
            <input
              type="text"
              value={bidAmount}
              onChange={(e) => setBidAmount(e.target.value)}
              className="border border-gray-500 p-2 mr-2 w-32"
              placeholder="Enter bid amount"
            />
            <button
              onClick={() => handleBid(product)}
              className="bg-gray-400 hover:bg-gray-500 text-black py-2 px-4 border border-gray-600"
            >
              Bid
            </button>
          </div>
        )}
        {product.type === ProductType.Sale && (
          <button
            onClick={addCart}
            className="bg-gray-400 hover:bg-gray-500 text-black py-2 px-4 border border-gray-600 mt-4"
          >
            Add to Cart
          </button>
        )}
      </div>
    </div>
  );
};

export default ProductDetailsPage;
