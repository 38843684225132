import {
    prepareWriteContract,
    waitForTransaction,
    writeContract,
    readContract
  } from "@wagmi/core";
  import React, { useCallback, useEffect, useState } from "react";
  import StakingAbi from "../../contract/abi/Staking.json";
  import { ethers } from "ethers";
  import useErc20Token from "./useErc20";
  import { useDexscreenerPrice } from "./useDashboard";
  
  const StakingAddress = "0x09127fD097a689105A63a04FE526a22d871C91Cd";
  
  const tokenPair = "0x7e2971c3b22718a1EE5405C2503914FEEC7ABF89";
  
  const useStaking = () => {
    
  const [claimOpen, setClaimOpen] = useState(false);
  const { price } = useDexscreenerPrice(tokenPair);
   const { BalanceOf } = useErc20Token()

    const Deposit = async (price: string) => {
      const PriceInEth = ethers.utils.parseEther(price);
      
      try {
        const { request } = await prepareWriteContract({
          address: StakingAddress,
          abi: StakingAbi,
          functionName: "deposit",
          args: [PriceInEth],
        });
        const { hash } = await writeContract(request);
  
        const data = await waitForTransaction({
          hash,
        });
  
        console.log(`Transaction data:`, data.logs[0].address);
        console.log(data);
  
      
        return data;
      } catch (error) {
        console.error("Transaction failed", error);
        throw error;
      }
    };
  
    const Withdraw = async (price: string) => {
      const PriceInEth = ethers.utils.parseEther(price);
  
      try {
        const { request } = await prepareWriteContract({
          address: StakingAddress,
          abi: StakingAbi,
          functionName: "withdraw",
          args: [PriceInEth],
        });
        const { hash } = await writeContract(request);
  
        const data = await waitForTransaction({
          hash,
        });
  
        console.log(`Transaction data:`, data.logs[0].address);
        console.log(data);
  
 
        return data;
      } catch (error) {
        console.error("Transaction failed", error);
        throw error;
      }
    };


    const StakingBalance = useCallback(async (saleAddress: string | undefined) => {
        try {
          const balanceStake = await readContract({
            address: StakingAddress,
            abi: StakingAbi,
            functionName: "stakingBalance",
            args: [saleAddress],
          });
          const balanceinWei = ethers.BigNumber.from(balanceStake);
          const BalanceInEther = ethers.utils.formatEther(balanceinWei);
           
          console.log("Stake:", balanceStake);
          return BalanceInEther as string;
        } catch (error) {
          console.error("Error reading price from contract", error);
          throw error;
        }
      }, []);

      
      const isClaim = useCallback(async () => {
        try {
          const isOpen = await readContract({
            address: StakingAddress,
            abi: StakingAbi,
            functionName: "claimOpen",
          });
          
          console.log("claimOpen:", isOpen);
          return isOpen as boolean;
        } catch (error) {
          console.error("Error reading claimOpen from contract", error);
          throw error;
        }
      }, []);


      const TVL = async () => {
        try {
          const totalbalance = await BalanceOf(StakingAddress);
          const tvlValue = Number(totalbalance) * Number(price);
          console.log("TVL", tvlValue);
      
          const roundedTvl = Math.round(Number(tvlValue));
          const formattedTVL = roundedTvl.toLocaleString();
          
          return formattedTVL as string;
        } catch (error) {
          console.error("TVL failed", error);
          throw error;
        }
      };
      
      useEffect(() => {
        isClaim().then(result => {
          setClaimOpen(result);
        });
      }, [isClaim]);
  
    return { Deposit, Withdraw, StakingBalance, TVL };
  };
  
  export default useStaking;
  