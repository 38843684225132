import React, { useState } from "react";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount } from "wagmi";
import { Link } from "react-router-dom";
import ScrollingBanner from "../components/ScrollingBanner";
import RevenueShareDashboard from "../components/RevenueShareDashboard";
import Staking from "../components/Staking";

const caText = "CA: 0x9470e1E043E87c3e74820b6da120e730D8d850Df";

const Navbar: React.FC = () => {
  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const formattedAddress: string = address
    ? `${address.slice(0, 6)}...${address.slice(-4)}`
    : "Connect Wallet";

  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(`Searching for: ${searchTerm}`);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
    <div className="bg-gray-100 px-4 py-3 sm:py-2 text-center text-sm sm:text-base text-gray-600 border-b border-gray-300">
        <p>{caText}</p>
      </div>
      <ScrollingBanner />
      <nav className="bg-gray-200 border-b-2 border-gray-400 px-4 py-2">
        <div className="flex items-center justify-between">
        <Link to="https://www.thenewroad.co/" className="shrink-0">
            <img src="/logo.png" alt="Logo" className="h-28 w-54" />
          </Link>

          {/* Search bar only for md screens and larger */}
          <div className="hidden md:flex md:flex-grow md:items-center mx-4">
            <form onSubmit={handleSearch} className="flex-grow flex">
              <label htmlFor="search" className="text-black mr-3 font-medium hidden lg:block">
                Search
              </label>
              <div className="flex flex-grow">
                <input
                  type="text"
                  id="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="px-2 py-1 flex-grow rounded-l bg-white text-black border-2 border-r-0 border-gray-400 focus:outline-none"
                />
                <button
                  type="submit"
                  className="px-3 sm:px-4 py-1 text-sm font-semibold bg-gray-400 hover:bg-gray-500 active:bg-gray-600 rounded-r text-white"
                >
                  Go
                </button>
              </div>
            </form>
          </div>

          {/* Hamburger menu button */}
          <button onClick={toggleMenu} className="md:hidden">
  <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
    {isMenuOpen ? (
     
      <path fillRule="evenodd" clipRule="evenodd" d="M18.707 5.293a1 1 0 010 1.414L13.414 12l5.293 5.293a1 1 0 11-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 11-1.414-1.414L10.586 12 5.293 6.707a1 1 0 111.414-1.414L12 10.586l5.293-5.293a1 1 0 011.414 0z" />
    ) : (
 
      <path fillRule="evenodd" clipRule="evenodd" d="M4 5h16a1 1 0 110 2H4a1 1 0 110-2zm0 7h16a1 1 0 110 2H4a1 1 0 110-2zm0 7h16a1 1 0 110 2H4a1 1 0 110-2z" />
    )}
  </svg>
</button>

          {/* Links and buttons for medium screens and larger */}
          <div className="hidden md:flex space-x-4 items-center">
            <Link className="font-bold px-3 sm:px-4 py-1 text-sm font-semibold text-gray-700 hover:text-gray-800" to="/form">
              Add Product
            </Link>
            <Link className="font-bold px-3 sm:px-4 py-1 text-sm font-semibold text-gray-700 hover:text-gray-800" to="/cart">
              Cart
            </Link>
            <Link className="font-bold px-3 sm:px-4 py-1 text-sm font-semibold text-gray-700 hover:text-gray-800" to="/account">
              Account
            </Link>
            <Link className="font-bold px-3 sm:px-4 py-1 text-sm font-semibold text-gray-700 hover:text-gray-800" to="https://revshare.thenewroad.co/RevenueShareDashboard">
            Rev Sharing
            </Link>
            <Link className="font-bold px-3 sm:px-4 py-1 text-sm font-semibold text-gray-700 hover:text-gray-800" to="/staking">
            Staking
            </Link>
            <Link className="font-bold px-3 sm:px-4 py-1 text-sm font-semibold text-gray-700 hover:text-gray-800" to="/chat">
              Chat
            </Link>
            <Link className="font-bold px-3 sm:px-4 py-1 text-sm font-semibold text-gray-700 hover:text-gray-800" to="https://twitter.com/TheNewRoad_">
              Twitter
            </Link>
            <Link className="font-bold px-3 sm:px-4 py-1 text-sm font-semibold text-gray-700 hover:text-gray-800" to="https://t.co/CEmADFvSac">
              Telegram
            </Link>
            <button className="px-4 py-1 text-sm font-semibold bg-gray-300 hover:bg-gray-400 active:bg-gray-500 rounded-full border-2 border-gray-400 shadow" onClick={open}>
              {formattedAddress}
            </button>
          </div>
        </div>

        {/* Mobile menu */}
        <div className={`md:hidden ${isMenuOpen ? "block" : "hidden"}`}>
          <Link to="/form" className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100">
            Add Product
          </Link>
          <Link to="/cart" className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100">
            Cart
          </Link>
          <Link to="/account" className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100">
            Account
          </Link>
          <Link to="https://revshare.thenewroad.co/RevenueShareDashboard" className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100">
          Rev Sharing
          </Link>
          <Link to="/staking" className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100">
          Staking
          </Link>
          <Link to="/chat" className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100">
            Chat
          </Link>
          <Link to="https://twitter.com/TheNewRoad_" className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100">
            Twitter
          </Link>
          <Link to="https://t.co/CEmADFvSac" className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100">
            Telegram
          </Link>
          <button onClick={open} className="block w-full text-left py-2 px-4 text-sm text-gray-700 hover:bg-gray-100">
            {formattedAddress}
          </button>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
