import {
  prepareWriteContract,
  waitForTransaction,
  writeContract,
  readContract,
} from "@wagmi/core";
import React, { useCallback } from "react";
import AuctionABI from "../../contract/abi/Auction.json";
import { BigNumber, ethers } from "ethers";
import { parseEther, parseGwei } from "viem";

const useAuction = () => {
  /**  const auctionEndTime = useCallback(async (AuctionAddress: string) => {
    try {
      const result = await readContract({
        address: AuctionAddress as `0x${string}`,
        abi: AuctionABI,
        functionName: "auctionEndTime",
      });

      if (typeof result !== "number") {
        throw new Error(
          "Expected the contract to return a number for auctionEndTime"
        );
      }

      const dateCorrectFormat = new Date(
        Number(result.toString().slice(0, -1)) * 1000
      );
      console.log("EndTime:", dateCorrectFormat);
      return dateCorrectFormat;
    } catch (error) {
      console.error("Error reading auctionEndTime from contract", error);
      throw error;
    }
  }, []);*/

  const BidCount = useCallback(async (AuctionAddress: string) => {
    console.log(AuctionAddress)
    try {
      const bidCount = await readContract({
        address: AuctionAddress as `0x${string}`,
        abi: AuctionABI,
        functionName: "bidCount",
      });

      console.log("BidCount:", BidCount);
      return bidCount;
    } catch (error) {
      console.error("Error reading bidCount from contract", error);
      throw error;
    }
  }, []);

  const StartingPrice = useCallback(
    async (AuctionAddress: string | undefined) => {
      try {
        const startingPrice = await readContract({
          address: AuctionAddress as `0x${string}`,
          abi: AuctionABI,
          functionName: "startingPrice",
        });
        console.log("startingPrice:", startingPrice);
        const priceWeiBigNumber = ethers.BigNumber.from(startingPrice);
        const priceInEther = ethers.utils.formatEther(priceWeiBigNumber);
        console.log("priceInEther:", priceInEther);
        return priceInEther as string;
      } catch (error) {
        console.error("Error reading StartingPrice from contract", error);
        throw error;
      }
    },
    []
  );

  const Bid = async (AuctionAddress: string, value: string) => {
    try {
     
       console.log(AuctionAddress)
      const { request } = await prepareWriteContract({
        address: AuctionAddress as `0x${string}`,
        abi: AuctionABI,
        functionName: "bid",
        value: parseEther(value),
      });
      const { hash } = await writeContract(request);

      const data = await waitForTransaction({
        hash,
      });

      console.log("Tx hash");
      return data;
    } catch (error) {
      console.error("Error Bid from contract", error);
      throw error;
    }
  };

  const HighestBid = useCallback(async (AuctionAddress: string | undefined) => {
    try {
      const highestBid = await readContract({
        address: AuctionAddress as `0x${string}`,
        abi: AuctionABI,
        functionName: "highestBid",
      });
      console.log("highestBid:", highestBid);
      const highestBidWeiBigNumber = ethers.BigNumber.from(highestBid);
      const highestBidInEther = ethers.utils.formatEther(
        highestBidWeiBigNumber
      );

      console.log("highestBid:", highestBidInEther);
      return (highestBidInEther);
    } catch (error) {
      console.error("Error reading highestBid from contract", error);
      throw error;
    }
  }, []);

  const HighestBidder = useCallback(
    async (AuctionAddress: string | undefined) => {
      try {
        const highestBidder = await readContract({
          address: AuctionAddress as `0x${string}`,
          abi: AuctionABI,
          functionName: "highestBidder",
        });

        console.log("highestBidder:", highestBidder);
        return highestBidder as string;
      } catch (error) {
        console.error("Error reading highestBidder from contract", error);
        throw error;
      }
    },
    []
  );

  const BidderConfirm = async (AuctionAddress: string) => {
    try {
      const { request } = await prepareWriteContract({
        address: AuctionAddress as `0x${string}`,
        abi: AuctionABI,
        functionName: "bidderConfirms",
      });
      const { hash } = await writeContract(request);

      const data = await waitForTransaction({
        hash,
      });

      console.log("Tx hash", data);
      return data;
    } catch (error) {
      console.error("Error bidderConfirms from contract", error);
      throw error;
    }
  };

  const auctionEnd = async (AuctionAddress: string) => {
    try {
      const { request } = await prepareWriteContract({
        address: AuctionAddress as `0x${string}`,
        abi: AuctionABI,
        functionName: "auctionEnd",
      });
      const { hash } = await writeContract(request);

      const data = await waitForTransaction({
        hash,
      });

      console.log("Tx hash", data);
      return data;
    } catch (error) {
      console.error("Error auctionEnd from contract", error);
      throw error;
    }
  };

  return {
    BidCount,
    StartingPrice,
    Bid,
    HighestBid,
    HighestBidder,
    BidderConfirm,
    auctionEnd,
  };
};

export default useAuction;
