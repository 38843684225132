import { useState, useEffect } from "react";
import { db } from "../../firebaseConfig"; // Adjust the import path as necessary
import {
  Product,
  ProductType,
  AuctionProduct,
  SaleProduct,
} from "../../interface/data"; // Adjust the import path

const usePaidProducts = () => {
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    const unsubscribe = db
      .collection("products")
      .where("hasPaid", "==", true)
      .onSnapshot(
        (snapshot) => {
          const unpaidProducts = snapshot.docs
            .map((doc) => {
              const data = doc.data();
              const product = { ...data, productId: doc.id } as Product;

              return product;
            })
            .filter(Boolean);

          setProducts(unpaidProducts as Product[]);
        },
        (err) => {
          console.error(`Encountered error: ${err}`);
        }
      );

    return () => {
      unsubscribe();
    };
  }, []);

  return products;
};

export default usePaidProducts;
